import * as Styled from './OxStory.styled';

import React, { ReactFragment } from 'react';
import { OxTextTagline } from 'src/components/OxTextTagline';
import { OxTextHeading } from 'src/components/OxTextHeading';
import { OxTextParagraph } from 'src/components/OxTextParagraph';
import { TLooseObject } from 'src/services/api/api.types';
import { generateImgSizes } from 'src/utils';
import { OxContainerGutter } from 'src/components/OxContainer';
import { OxImage } from 'src/components/OxImage';
import { getGatsbyImageDataFromImage } from 'src/services/cms/dataConverter';
import { fireEvent } from 'src/helpers/TagManager';

export enum EOxStoryVariant {
    ImageRight = 'ImageRight',
    ImageLeft = 'ImageLeft'
}

type TProps = {
    fluidImagesArray?: TLooseObject[] | TLooseObject[][];
    label: string;
    title?: string;
    description: string | ReactFragment;
    linkTitle?: string;
    linkUrl?: string;
    linkClick?: () => void;
    variant: EOxStoryVariant;
    fullBleedContent?: boolean;
    isLast?: boolean;
};

export const OxStory = ({
    fluidImagesArray = [],
    label,
    title,
    description,
    descriptionImage,
    linkTitle,
    linkUrl,
    linkClick,
    variant,
    fullBleedContent,
    isLast,
    ...props
}: SCProps<'div', TProps>): JSX.Element => {
    const multipleImages =
        Array.isArray(fluidImagesArray) &&
        fluidImagesArray[0] &&
        Array.isArray(fluidImagesArray[0]) &&
        fluidImagesArray.length > 1;

    const imageSizes = generateImgSizes({
        values: OxContainerGutter,
        formatter: (val, breakpoint) => `${(breakpoint === 'xs' ? 100 : 50) - val * 2}vw`
    });

    const onLinkClick = (): void => {
        linkClick;
        if (window.location.pathname === '/services/facial-structure') {
            fireEvent({
                event: 'StoryLinkClick'
            });
        }
    };

    if (descriptionImage) {
        descriptionImage = getGatsbyImageDataFromImage(descriptionImage);
    }

    return (
        <Styled.Container variant={variant} {...props}>
            {fluidImagesArray && (
                <>
                    {multipleImages ? (
                        <Styled.AutoScrollAsset
                            sizes={imageSizes}
                            alt={title}
                            images={fluidImagesArray}
                            $fullBleedContent={fullBleedContent}
                        />
                    ) : (
                        <Styled.Asset
                            alt={title}
                            sizes={imageSizes}
                            fluidImages={fluidImagesArray[0]}
                            $fullBleedContent={fullBleedContent}
                        />
                    )}
                </>
            )}

            <Styled.ContentWrapper
                variant={variant}
                $fullBleedContent={fullBleedContent}
                isLast={isLast}
            >
                <OxTextTagline>{label}</OxTextTagline>
                {title && <OxTextHeading>{title}</OxTextHeading>}
                {description && <OxTextParagraph>{description}</OxTextParagraph>}
                {descriptionImage && (
                    <Styled.ImageWrapper>
                        <OxImage image={descriptionImage} />
                    </Styled.ImageWrapper>
                )}
                {!!linkTitle && !!(linkUrl || linkClick) && (
                    <Styled.Link to={linkUrl} onClick={onLinkClick}>
                        {linkTitle}
                    </Styled.Link>
                )}
            </Styled.ContentWrapper>
        </Styled.Container>
    );
};
