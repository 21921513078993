import * as Styled from './OxAutoScrollImages.styled';

import React, { useEffect, useState } from 'react';
import { EAnimatedWrapperAnimation, OxAnimatedWrapper } from 'src/components/OxAnimatedWrapper';
import { OxImage } from 'src/components/OxImage';

type TProps = {
    alt: string;
    images: any[];
    animation?: EAnimatedWrapperAnimation;
    intervalTimeout?: number;
    sizes?: string;
};

let intervalId: NodeJS.Timeout | false;

export const OxAutoScrollImages = ({
    alt,
    images,
    sizes,
    intervalTimeout = 5000,
    animation = EAnimatedWrapperAnimation.FadeIn,
    ...props
}: SCProps<'div', TProps>): JSX.Element => {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    useEffect(() => {
        // intervalId =
        //   images.length > 1 &&
        //   setInterval(
        //     () =>
        //       setActiveIndex(prev => (prev >= images.length - 1 ? 0 : prev + 1)),
        //     intervalTimeout
        //   );

        return () => {
            intervalId && clearInterval(intervalId);
        };
    }, []);

    return (
        <Styled.Container {...props}>
            {images.map((image, index) => (
                <OxAnimatedWrapper
                    relativeBox
                    key={index}
                    animation={animation}
                    active={activeIndex === index}
                    isNext={activeIndex + 1 === index}
                >
                    <OxImage alt={alt} fluidImages={image} sizes={sizes} />
                </OxAnimatedWrapper>
            ))}
        </Styled.Container>
    );
};
