import * as Styled from './OxTextParagraph.styled';

import React, { PropsWithChildren } from 'react';
import { TextAlign } from 'csstype';

type TProps = PropsWithChildren<{
    textAlign?: TextAlign;
    fontSize?: number[];
    allowLineBreaks?: boolean;
}>;

export const OxTextParagraph = ({
    textAlign,
    fontSize,
    allowLineBreaks,
    children
}: TProps): JSX.Element => {
    return (
        <Styled.Paragraph
            textAlign={textAlign}
            fontSize={fontSize}
            allowLineBreaks={allowLineBreaks}
        >
            {children}
        </Styled.Paragraph>
    );
};
