import { createTextStyle } from 'src/utils/createTextStyle';
import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';
import { fluidSizing } from 'src/utils';
import { TextAlign } from 'csstype';

export const Paragraph = styled.p<{
    textAlign?: TextAlign;
    fontSize?: number[];
    allowLineBreaks?: boolean;
}>(
    ({ textAlign, fontSize, allowLineBreaks }) => css`
        ${textAlign &&
        css`
            text-align: ${textAlign};
        `}

        ${allowLineBreaks &&
        css`
            white-space: pre-line;
        `}

    white-space: pre-line;

        ${createTextStyle(
            EFonts.Montserrat,
            EFontWeight.Medium,
            fontSize ?? [11, 11, 11],
            [14, 14, 14]
        )}

        ${fluidSizing([{ prop: 'margin-bottom', values: [14, 14, 14] }])}

    &:last-child {
            margin-bottom: 0;
        }
    `
);
