import * as Styled from './OxLineHeader.styled';

import React, { PropsWithChildren } from 'react';

type TProps = PropsWithChildren<unknown>;

export const OxLineHeader = ({ children }: TProps): JSX.Element => {
    return (
        <Styled.Container>
            <Styled.Title>
                <Styled.Text>{children}</Styled.Text>
            </Styled.Title>
            <Styled.Line />
        </Styled.Container>
    );
};
