import * as Styled from './OxBanner.styled';

import React, { useEffect, useState } from 'react';

import { OxBannerItem, TBannerItem } from 'src/components/OxBanner/components/OxBannerItem';
import { EAnimatedWrapperAnimation, OxAnimatedWrapper } from 'src/components/OxAnimatedWrapper';

type TProps = {
    items: TBannerItem[];
    eagerLoad: boolean;
};

const intervalTimeout = 5000;
let interval: NodeJS.Timeout;

export const OxBanner = ({ items = [], eagerLoad }: TProps): JSX.Element => {
    const [componentIsReady, setComponentIsReady] = useState(false);
    const [activeSlide, setActiveSlide] = useState<number>(0);

    useEffect(() => {
        if (typeof interval !== 'undefined') clearInterval(interval);

        interval = setInterval(() => {
            setActiveSlide((prev) => prev + 1);
        }, intervalTimeout);
    }, [componentIsReady]);

    useEffect(() => {
        let unmounted = false;

        setTimeout(() => {
            !unmounted && setComponentIsReady(true);
        }, 1000);

        return () => {
            unmounted = true;
            !!interval && clearInterval(interval);
        };
    }, []);

    return (
        <Styled.Container>
            {items.length === 1 && (
                <OxBannerItem
                    {...items[0]}
                    componentIsReady={componentIsReady}
                    eagerLoad={eagerLoad}
                />
            )}
            {items.length > 1 &&
                items.map((item, index) => (
                    <OxAnimatedWrapper
                        relativeBox
                        key={index}
                        animation={EAnimatedWrapperAnimation.FadeIn}
                        active={index === activeSlide % items.length}
                    >
                        <OxBannerItem
                            {...item}
                            componentIsReady={componentIsReady}
                            eagerLoad={eagerLoad}
                        />
                    </OxAnimatedWrapper>
                ))}
        </Styled.Container>
    );
};
