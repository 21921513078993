import { EFonts, EFontWeight } from 'src/config/enums';
import { EUnits, fluidSizing, toVw } from 'src/utils';
import styled, { css } from 'styled-components';

import { OxContainer } from 'src/components/OxContainer';
import { OxImage } from '../OxImage';
import { OxVideoPlayer } from '../OxVideoPlayer';
import { OxWaveText } from 'src/components/OxWaveText';
import { createTextStyle } from 'src/utils/createTextStyle';

export const Container = styled.div(
    ({ theme }) => css`
        width: 100%;
        position: relative;
        transition: 1s;
        ${theme.breakpoints.only('xs')} {
            display: flex;
            flex-direction: column;
        }
    `
);

export const VideoPlayer = styled(OxVideoPlayer)(
    ({ theme }) => css`
        ${theme.breakpoints.only('xs')} {
            flex: 1;
            margin-bottom: ${toVw(20, 'xs')};
        }
    `
);

export const Image = styled(OxImage)(
    ({ theme }) => css`
        max-height: 100%;
        ${theme.breakpoints.only('xs')} {
            flex: 1;
            margin-bottom: ${toVw(20, 'xs')};
        }
    `
);

export const Content = styled(OxContainer)<{
    isOverlaidTextBlack?: boolean;
    hide?: boolean;
}>(
    ({ theme, isOverlaidTextBlack, hide }) => css`
        opacity: 1;
        transition: opacity 500ms;
        will-change: opacity;

        ${theme.breakpoints.up('s')} {
            ${hide &&
            css`
                opacity: 0;
            `}
        }

        ${theme.breakpoints.only('xs')} {
            color: ${theme.colors.basic.black};
        }

        ${theme.breakpoints.up('s')} {
            position: absolute;
            height: 100%;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            ${!isOverlaidTextBlack &&
            css`
                color: ${theme.colors.basic.white};
            `}
        }

        ${theme.breakpoints.up('s')} {
            height: 60%; /* nudge text content down */
        }
    `
);

export const Title = styled(OxWaveText)`
    ${fluidSizing([{ prop: 'margin-bottom', values: [10, 10, 10] }])};
    h2 {
        text-transform: uppercase;
        ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [25, 30, 35], [30, 35, 45])}
    }
`;

export const Sub = styled(OxWaveText)`
    h1 {
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [11, 11, 11], [16, 16, 16])}
    }
`;
